name_ok = false;
phone_ok = false;
email_ok = false;
recaptcha_ok = false;


$(document).on('turbolinks:load', function() {
    $('#contact-form-name').on('change', contactFormCheckName);
    $('#contact-form-name').on('keyup', contactFormCheckName);
    $('#contact-form-phone').on('change', contactFormCheckPhone);
    $('#contact-form-phone').on('keyup', contactFormCheckPhone);
    $('#contact-form-email').on('change', contactFormCheckEmail);
    $('#contact-form-email').on('keyup', contactFormCheckEmail);
    contactFormCheckName();
    contactFormCheckPhone();
    contactFormCheckEmail();
})

contactFormCheckName = function() {
    if($('#contact-form-name').length > 0) {
      $('#contact-form-name').toggleClass('is-invalid', !(name_ok = ($('#contact-form-name').val().length > 0)));
      checkSubmit();
    }
}

contactFormCheckPhone = function() {
    if($('#contact-form-phone').length > 0) {
      $('#contact-form-phone').toggleClass('is-invalid', !(phone_ok = ($('#contact-form-phone').val().length > 0)));
      checkSubmit();
    }
  }

contactFormCheckEmail = function() {
    if($('#contact-form-email').length > 0) {
      $('#contact-form-email').toggleClass('is-invalid', !(email_ok = ($('#contact-form-email').val().length > 0)));
      checkSubmit();
    }
}
  
checkSubmit = function() {
  	 $('#contact-form-send').prop('disabled', !(name_ok && phone_ok && email_ok && recaptcha_ok));
}

recaptchaCallback = function() {
    recaptcha_ok = true;
      checkSubmit();
};

